import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { HiylloIcon, isHiylloIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { faCalendar, faComments, faEnvelope, faFolder, faPersonToPortal, faSquareKanban, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { MouseInteraction } from '@hiyllo/ux/animation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWrappedPopOver } from '@hiyllo/ux/context-menu';
import { useTheme } from '@hiyllo/ux/theme';
import { Button } from '@hiyllo/ux/button';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

const IS_MOBILE = window.innerHeight > window.innerWidth;

export const FeatureMenuItem = React.memo(function FeatureMenuItem(props: {
  icon: IconDefinition | HiylloIcon;
  title: string;
  description: string;
  href?: string;
}): JSX.Element {
  const $theme = useTheme();

  return (
    <a href={props.href} style={{ textDecoration: 'none', color: $theme.foreground }}>
      <MouseInteraction factorShift={0.75}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            cursor: 'pointer',
            userSelect: 'none'
          }}
        >
          <div
            style={{
              height: 50,
              width: 50,
              borderRadius: 10,
              fontSize: 20,
              // border: `1px solid ${$theme.foregroundInactive ?? ''}`,
              background: 'rgba(255, 255, 255, 0.1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0
            }}
          >
            {isHiylloIcon(props.icon) ?
              <props.icon width={20} color="white"/>
            :
              <FontAwesomeIcon icon={props.icon}/>
            }
          </div>
          <div>
            <div
              style={{
                fontSize: 17.5,
                fontWeight: 'bold'
              }}
            >{props.title}</div>
            <div style={{ fontSize: 15 }}>{props.description}</div>
          </div>
        </div>
      </MouseInteraction>
    </a>
  );
});

const SolutionsMenuIcon = React.memo(function SolutionsMenuIcon(): JSX.Element {
  const [showMenu, setShowMenu] = React.useState(true);
  const { open, ref, CXMenuContainer } = useWrappedPopOver<HTMLDivElement>({ zIndex: 100000, displayMode: 'fixed', disableMaxHeight: true });

  return (
    <div
      style={{ position: 'relative' }}
      // onMouseEnter={() => setShowMenu(true)}
      // onMouseLeave={() => setShowMenu(false)}
    >
      { /* @ts-expect-error --- */ }
      <MouseInteraction factorShift={2}>
        <div
          style={{ userSelect: 'none', cursor: 'pointer', paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}
          onClick={open}
          ref={ref}
        >
          Solutions <FontAwesomeIcon icon={faCaretDown}/>
        </div>
      </MouseInteraction>
      <CXMenuContainer style={{ overflow: 'visible' }}>
        <div
          style={{
            paddingLeft: 12.5,
            paddingTop: 27.5
          }}
        >
          <motion.div
            initial={{ translateY: -10, boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0)', backdropFilter: 'blur(5px)' }}
            animate={{ translateY: 0, boxShadow: 'rgba(20, 20, 20, 0.75) 0px 0px 20px 10px', backdropFilter: 'blur(15px)' }}
            transition={{ duration: 0.2 }}
            style={{
              background: 'rgba(0, 0, 0, 0.5)',
              borderRadius: 10
            }}
          >
            <div
              style={{
                width: 350,
                padding: 10,
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                gap: 10
              }}
            >
              <FeatureMenuItem
                icon={faPersonToPortal}
                title="Onboarding"
                description='With everything in one place, onboarding has never been simpler'
                href='/s/onboarding'
              />
            </div>
          </motion.div>
        </div>
      </CXMenuContainer>
    </div>
  );
});

const FeaturesMenuIcon = React.memo(function FeaturesMenuIcon(): JSX.Element {
  const [showMenu, setShowMenu] = React.useState(true);
  const { open, ref, CXMenuContainer } = useWrappedPopOver<HTMLDivElement>({ zIndex: 100000, displayMode: 'fixed', disableMaxHeight: true });

  return (
    <div
      style={{ position: 'relative' }}
      // onMouseEnter={() => setShowMenu(true)}
      // onMouseLeave={() => setShowMenu(false)}
    >
      { /* @ts-expect-error --- */ }
      <MouseInteraction factorShift={2}>
        <div
          style={{ userSelect: 'none', cursor: 'pointer', paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}
          onClick={open}
          ref={ref}
        >
          Features <FontAwesomeIcon icon={faCaretDown}/>
        </div>
      </MouseInteraction>
      <CXMenuContainer style={{ overflow: 'visible' }}>
        <div
          style={{
            paddingLeft: 12.5,
            paddingTop: 27.5
          }}
        >
          <motion.div
            initial={{ translateY: -10, boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0)', backdropFilter: 'blur(5px)' }}
            animate={{ translateY: 0, boxShadow: 'rgba(20, 20, 20, 0.75) 0px 0px 20px 10px', backdropFilter: 'blur(15px)' }}
            transition={{ duration: 0.2 }}
            style={{
              background: 'rgba(0, 0, 0, 0.5)',
              borderRadius: 10
            }}
          >
            <div
              style={{
                width: 350,
                padding: 10,
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                gap: 10
              }}
            >
              <FeatureMenuItem
                icon={faComments}
                title="Chat"
                description='Chat that keeps your team connected, securely and seamlessly'
                href='/f/chat'
              />
              <FeatureMenuItem
                icon={faSquareKanban}
                title="Task Management"
                description='Tasks, Projects, and Sprints, scalable to enterprise, yet intuitive for individuals'
                href='/f/tasks'
              />
              <FeatureMenuItem
                icon={faCalendar}
                title="Calendar"
                description='Reactive calendar that unblocks teams and keeps things moving'
                href='/f/calendar'
              />
              <FeatureMenuItem
                icon={faEnvelope}
                title="Mail"
                description='AI-powered modern email that keeps you connected with your partners'
                href='/f/mail'
              />
              <FeatureMenuItem
                icon={faFolder}
                title="File Storage & Docs"
                description='Effortless file storage, and modern docs that makes writing a dream'
                href='/f/stuff'
              />
              <FeatureMenuItem
                icon={VideoMeetingIcon}
                title="Hiyllo Meet"
                description='Crystal-clear video meetings, connections without limits'
                href='/f/meet'
              />
            </div>
          </motion.div>
        </div>
      </CXMenuContainer>
    </div>
  );
});

export const InfoHeader = React.memo(function InfoHeader (): JSX.Element {
  const { scrollY } = useScroll({
    target: { current: window.document.body },
    offset: ['start end', 'end start'],
    container: { current: document.getElementById('root') }
  });
  const whiteIconOpacity = useTransform(scrollY, [0, window.innerHeight], [1, 0]);
  const colorIconOpacity = useTransform(scrollY, [0, window.innerHeight], [0, 1]);

  return (
    <div style={{ position: 'fixed', zIndex: 100, backdropFilter: 'blur(20px)', top: 0, left: 0, height: 90, width: IS_MOBILE ? 'calc(100% - 30px)' : 'calc(100% - 60px)', paddingLeft: 30, paddingRight: IS_MOBILE ? 0 : 30, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ position: 'relative', width: IS_MOBILE ? 20 : 40, height: IS_MOBILE ? 20 : 40, cursor: 'pointer' }} onClick={() => {
        window.location.href = '/';
      }}>
        <motion.img src="https://cdn.hiyllo.net/logo/work/icon-white.png" style={{ height: IS_MOBILE ? 20 : 40, width: 'auto', opacity: whiteIconOpacity }}/>
        <motion.img src={IS_MOBILE ? 'https://cdn.hiyllo.net/logo/work/icon-gradient.png' : '/work-logo.png'} style={{ height: IS_MOBILE ? 20 : 40, width: 'auto', opacity: colorIconOpacity, position: 'absolute', top: 0, left: 0 }}/>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
        {!IS_MOBILE ? <SolutionsMenuIcon/> : null}
        {!IS_MOBILE ? <FeaturesMenuIcon/> : null}
        { /* @ts-expect-error --- */ }
        <MouseInteraction factorShift={2}>
          <div onClick={() => {
            window.location.href = '/pricing';
          }} style={{ cursor: 'pointer', paddingLeft: 20, paddingRight: 20 }}>
            Pricing
          </div>
        </MouseInteraction>
        {!IS_MOBILE ?
          <Button
            style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}
            label="Try it free for 3 weeks"
            onClick={() => {
              window.location.href = 'https://www.hiyllo.cloud/f/create?then=https%3A%2F%2Fwww.hiyllo.cloud%2Fia%2F%3Ctenant%3E.hiyllo.business%3Fthen%3D%252Fproducts%252Fcreate%252Fwork%252Fi';
            }}
          />
        : null}
      </div>
    </div>
  );
});
