import React from 'react';
import {
  BrowserRouter, Navigate, Route, Routes
} from 'react-router-dom';
import { Home } from './views/home';
import { PageWithNavbar } from './common';
import { HiylloAuthSSO } from './views/employee-sso';
import { Admin } from './views/admin';
import { Landing } from './views/landing';
import { PrivacyPolicy } from './views/privacy-policy';
import { ProductsList } from './views/products/products-list';
import { ProductKind } from '../types/product';
import { ProductDetails } from './views/products/details';
import { BillingOverview } from './views/billing/billing-overview';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { TermsOfService } from './views/terms-of-service';
import { UserTermsOfUse } from './views/user-terms-of-use';
import { ChatPage } from './views/infosite/pages/chat';
import { TasksPage } from './views/infosite/pages/tasks';
import { CalendarPage } from './views/infosite/pages/calendar';
import { MailPage } from './views/infosite/pages/mail';
import { StuffPage } from './views/infosite/pages/stuff';
import { MeetPage } from './views/infosite/pages/meet';
import { PricingPage } from './views/infosite/pages/pricing';
import { ReferralTerms } from './views/referral-terms';
import { OnboardingPage } from './views/infosite/pages/onboarding';
import { QAEngineerTakehomePage } from './views/infosite/pages/qa-engineer-takehome';
import { APIView } from './views/api/api-view';
import { PartnersView } from './views/partners/partners-view';
import { IS_FEDERATED } from '../env';

const Error404 = React.memo(function Error404 (): JSX.Element {
  return (
    <EmptySplash
      icon={faExclamationCircle}
      label='404'
      hint="The page you're looking for doesn't exist."
    />
  );
});

export const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <PageWithNavbar>
        <Routes>
          <Route path="/" element={window.sessionStorage.employee === '1' ? <Admin/> : (IS_FEDERATED ? <Home/> : <Navigate to="/partners"/>)}/>
          <Route path="/work" element={<ProductsList kind={ProductKind.work}/>}/>
          <Route path="/support" element={<ProductsList kind={ProductKind.support}/>}/>
          <Route path="/billing" element={<BillingOverview/>}/>
          <Route path="/api" element={<APIView/>}/>
          <Route path="/partners" element={<PartnersView/>}/>
          <Route path="/products/:uuid" element={<ProductDetails/>}/>
          <Route path="/login" element={<Navigate to="/"/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/terms-of-service" element={<TermsOfService/>}/>
          <Route path="/referral-terms" element={<ReferralTerms/>}/>
          <Route path="/user-terms-of-use" element={<UserTermsOfUse/>}/>
          <Route path="*" element={<Error404/>}/>
        </Routes>
      </PageWithNavbar>
    </BrowserRouter>
  );
};

export const LoggedOutRouter = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={window.location.host.endsWith('.hiyllo.business')/* || window.location.hostname === 'localhost' */ ? <HiylloAuthSSO/> : <Landing/>}/>
        <Route path="/f/chat" element={<ChatPage/>}/>
        <Route path="/f/tasks" element={<TasksPage/>}/>
        <Route path="/f/calendar" element={<CalendarPage/>}/>
        <Route path="/f/mail" element={<MailPage/>}/>
        <Route path="/f/stuff" element={<StuffPage/>}/>
        <Route path="/f/meet" element={<MeetPage/>}/>
        <Route path="/s/onboarding" element={<OnboardingPage/>}/>
        <Route path="/takehome/qa-engineer" element={<QAEngineerTakehomePage/>}/>
        <Route path="/referral-terms" element={<ReferralTerms/>}/>
        <Route path="/pricing" element={<PricingPage/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/terms-of-service" element={<TermsOfService/>}/>
        <Route path="/user-terms-of-use" element={<UserTermsOfUse/>}/>
        <Route path="*" element={<HiylloAuthSSO/>}/>
      </Routes>
    </BrowserRouter>
  );
};
