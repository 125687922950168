import React from 'react';
import { InlineLink } from '@hiyllo/ux/inline-link';
import { styled } from '@hiyllo/ux/styled';

const IS_MOBILE = window.innerHeight > window.innerWidth;

const Column = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 10
});

export const Footer = React.memo(function Footer(): JSX.Element {
  if (IS_MOBILE) {
    return (
      <div style={{ textAlign: 'center', padding: 20, fontSize: 12 }}>
        <a href="https://www.hiyllo.com">
          <div>
            <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{ height: 20, width: 20, opacity: 0.75, paddingBottom: 10 }}/>
          </div>
        </a>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 20, justifyContent: 'center', alignItems: 'center', whiteSpace: 'nowrap' }}>
          {IS_MOBILE ?
            <div>&copy; Hiyllo Inc, 2025</div>
          :
            <div>&copy; Hiyllo Inc 2025, All Rights Reserved</div>
          }
          <InlineLink href='/privacy-policy' label='Privacy Policy' />
          <InlineLink href='mailto:support@hiyllo.com' label='support@hiyllo.com' />
          {!IS_MOBILE ?
            <iframe src="https://status.hiyllo.com/badge?theme=dark" width="250" height="30" frameBorder="0" scrolling="no" style={{ colorScheme: 'light' }}></iframe>
          : null}
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ textAlign: 'center', padding: 20, fontSize: 12.5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: 800, justifyContent: 'space-between', alignItems: 'flex-end', whiteSpace: 'nowrap' }}>
          <Column>
            <a href="https://www.hiyllo.com">
              <div>
                <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{ height: 15, width: 15, opacity: 0.9 }}/>
              </div>
            </a>
            <div>&copy; Hiyllo Inc, 2025</div>
            <div>All Rights Reserved</div>
          </Column>
          <Column>
            <div><b>Legal</b></div>
            <InlineLink href='/privacy-policy' label='Privacy Policy' />
            <InlineLink href='/terms-of-service' label='Terms of Service' />
          </Column>
          <Column>
            <div><b>Platform</b></div>
            <InlineLink href='https://status.hiyllo.com/' label='Status' />
            <InlineLink href='https://www.hiyllo.com/security' label='Security' />
          </Column>
          <Column>
            <div><b>Support</b></div>
            <InlineLink href='mailto:support@hiyllo.com' label='support@hiyllo.com' />
            <InlineLink href='https://support.hiyllo.work/section/de2d9b72-92dc-4b99-92f6-7bc9b08200a2' label='Hiyllo API Documentation' />
          </Column>
          <Column>
            <div><b>Trust</b></div>
            <div>Hiyllo Work is SOC 2 compliant</div>
            <InlineLink href='https://www.hiyllo.com/trust' label='Trust Center' />
          </Column>
        </div>
      </div>
    );
  }
});
